import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { RedirectComponent } from './component/redirect/redirect.component';
import { LanguageComponent } from './component/language/language.component';

import { RouterModule } from '@angular/router';

import { TimestampPipe } from './pipe/timestamp.pipe';
import { OrderByPipe } from './pipe/order-by.pipe';
import { PublicLayoutComponent } from './layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './layout/private-layout/private-layout.component';
import { FooterComponent } from './layout/component/footer/footer.component';
import { HeaderComponent } from './layout/component/header/header.component';
import { MenuComponent } from './layout/component/menu/menu.component';
import { EmptyComponent } from './component/empty/empty.component';
import { FileDropComponent } from './component/file-drop/file-drop.component';
import { StepIconComponent } from './step-icon/step-icon.component';
import { ListDialogComponent } from './component/scan/list-dialog/list-dialog.component';
import { ListboxModule } from 'primeng/listbox';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownComponent } from './component/dropdown/dropdown.component';
import { DropdownModule } from 'primeng/dropdown';
import { SignaturePadComponent } from './component/signature-pad/signature-pad.component';
import { ToggleablePanelComponent } from './component/toggleable-panel/toggleable-panel.component';
import { PanelModule } from 'primeng/panel';
import { ButtonComponent } from './component/button/button.component';
import { PanelComponent } from './component/panel/panel.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MasnoryGalleryComponent } from './component/masnory-gallery/masnory-gallery.component';

let exportComponents = [
  RedirectComponent,
  LanguageComponent,
  PublicLayoutComponent,
  PrivateLayoutComponent,
  EmptyComponent,
  FileDropComponent,
  StepIconComponent,
  ListDialogComponent,
  DropdownComponent,
  SignaturePadComponent,
  HeaderComponent,
  ToggleablePanelComponent,
  ButtonComponent,
  PanelComponent,
  MasnoryGalleryComponent
];

let exportPipes = [
  TimestampPipe,
  OrderByPipe
];

@NgModule({
  declarations: [
    ... exportComponents,
    ... exportPipes,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    ListDialogComponent,
    DropdownComponent,
    SignaturePadComponent,
    ToggleablePanelComponent,
    ButtonComponent,
    PanelComponent,
    MasnoryGalleryComponent,
    
    
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ListboxModule,
    DynamicDialogModule,
    DropdownModule,
    PanelModule,
    InputSwitchModule

  ],
  exports : [
    ...exportComponents, 
    ...exportPipes,
  ],
  
})
export class SharedModule { }
