import { Component , ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AppRoleModeService } from 'src/app/shared/service/app-role-mode.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { SessionService } from 'src/app/shared/service/session.service';
import { environment } from 'src/environments/environment';
import { pageConfig } from 'src/page-config';

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  language : string = '';
  authenticated : boolean = false;

  signInPath : string[] = [];
  signOutPath : string[] = [];
  addFilePath : string[] = [];
  checked: boolean = false;
  modetitle:string = "USER MODE";
  
  constructor(
    private languageServ : LanguageService,
    private sessionServ : SessionService,
    private elRef: ElementRef,
    private appMode: AppRoleModeService
  ){
    appMode.setMode(this.checked);
    this._setLanguage(this.languageServ.current);
    this.languageServ.language.subscribe((_language: string) => this._setLanguage(_language));
    
    this.sessionServ.onChanges.subscribe((_isAuth) => this.authenticated = _isAuth);
  }


  setAdminMode(){
    const root = this.elRef.nativeElement;
    this.appMode.setMode(this.checked);
    if(this.checked){
      root.style.setProperty('--inputSwitch-font-color', 'red');
      this.modetitle = "ADMIN MODE"
    }
    else{
      root.style.setProperty('--inputSwitch-font-color', 'grey');
      this.modetitle = "USER MODE"
    }
  }

  private _setLanguage (_language : string) {
    
    this.language = _language;

    this.signInPath = ['/', _language, ...pageConfig['pages']['signIn']];
    this.signOutPath = ['/', _language, ...pageConfig['pages']['signOut']];
    this.addFilePath = ['/', _language, ...pageConfig['pages']['addFile']];
  }
}
