<div class="row m-0 p-0">
    <div class="col-1 m-0 p-0">
        <div class="icon-container">
            <i (click)="clearPad()" class="fa fa-pencil fa-2x" style="background-color: rgb(215, 215, 215); color: #828282;"></i>
        </div>
    </div>
    <div class="col-10 m-0 p-0">
        <canvas  #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)" width="400" height="100">
        </canvas>
    </div>

    <div class="col-1 sig-bOK m-0 p-0">
        <button (click)="savePad()"  [ngStyle]="{'background-color': !this.signatureNeeded ? 'white' : '#707070', 'width': '100%' , 'height': 'auto'}"  style="width: 100%; height: auto;" >
            <span *ngIf="this.signatureNeeded" style="font-size: 1.5em;">OK</span>
            <i *ngIf="!this.signatureNeeded" class="fa fa-check" style="font-size: 2.2em; color: green; background-color: white;"></i>
        </button>
    </div>

    <div class="section-signature-separator">
        <div></div>
    </div>
</div>