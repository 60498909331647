import { IStringAnyMap } from "./app/app.types";

export const pageConfig : IStringAnyMap = {

    pages: {
        start: [],
        signIn: ['session', 'sign-in'],
        signOut: ['session', 'sign-out'],
        signUp: ['session', 'sign-up'],
        addFile: ['', 'add-file'],
        notFound: ['404'],
    },

    language: {
        available: { de: 'Deutsch', en: 'English' },
        default: 'en'
    },
    
    menu : {
        dashboard : [
            {
                type : 'headline',
                label : 'Dashboards'
            },
            {
                type: 'link',
                link : ['dashboard'],
                label : 'Statistic',
                icon : 'fa-line-chart'
            },
            {
                type : 'line',
            },
            {
                type: 'link',
                link : '/some_local_path',
                label : 'Local',
                icon : 'fa-line-chart'
            },
            {
                type : 'line',
            },
            {
                type: 'link',
                link : 'https://www.flyingspoon.de/',
                label : 'Flying Spoon',
                icon : 'fa-external-link'
            }
        ],
    }
}