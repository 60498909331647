import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements OnInit {
  public signatureNeeded!: boolean;
  signaturePad!: SignaturePad;
  @ViewChild('canvas') canvasEl!: ElementRef;
  signatureImg!: string;



  ngOnInit(){
    this.signatureNeeded = true
  }

  ngAfterViewInit(){
    if(this.signatureNeeded){
      this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
      this.signaturePad.clear();
    }
    console.log('in ng view after')
  }

  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
    this.signaturePad.on();
    this.signatureNeeded = true;
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    this.signatureNeeded = false;
    this.signaturePad.off();
  }
}
