
<div class="container">
    <ul class="nav nav-pills" style="padding-top: 0.5em;">
        <a [routerLink]="['/', language]" class="d-flex align-items-center mb-0 text-light text-decoration-none">
            <img src="../../../../../assets/Header/logo.png">
        </a>
        <div class="d-flex align-items-center mb-0 mb-md-0 me-md-auto text-light text-decoration-none" style="margin-right: auto;">
            <p-inputSwitch [(ngModel)]="checked" (ngModelChange)="setAdminMode()"></p-inputSwitch>
            <span class="input-switch-text" style="margin-left: 10px;margin-right: 10px;">{{modetitle}}</span>
        </div> 
        <li class="nav-item" class="nav-item"><a class="nav-link text-light" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/', language]"><i class="fa fa-trophy fa-2x" aria-hidden="true"></i></a></li>
        <li *ngIf="authenticated" class="nav-item"><a class="nav-link text-light" [routerLinkActive]="['active']" [routerLink]="['/', language, 'add-file']"><i class="fa fa-bell fa-2x" aria-hidden="true"></i></a></li>
        <li *ngIf="authenticated" class="nav-item"><a class="nav-link text-light" [routerLinkActive]="['active']" [routerLink]="['/', language, 'add-file']"><i class="fa fa-envelope fa-2x" aria-hidden="true"></i> </a></li>
        <li *ngIf="authenticated" class="nav-item"><a class="nav-link text-light" [routerLinkActive]="['active']" [routerLink]="['/', language, 'add-file']"><i class="fa fa-question fa-2x" aria-hidden="true"></i></a></li>
        <li *ngIf="authenticated" class="nav-item"><a class="nav-link text-light" [routerLinkActive]="['active']" [routerLink]="['/', language, 'add-file']"><i class="fa fa-cogs fa-2x" aria-hidden="true"></i></a></li>
        <li *ngIf="authenticated" class="nav-item"><a class="nav-link text-light" style="padding-top: 0px;" [routerLinkActive]="['active']" [routerLink]="['/', language, 'add-file']"><img src="../../../../../assets/Header/Group 1.png"></a></li>
        <li *ngIf="authenticated" class="nav-item"><a class="nav-link text-light" [routerLink]="signOutPath"><i class="fa fa-sign-out fa-2x" aria-hidden="true"></i></a></li>
        <li *ngIf="!authenticated" class="nav-item"><a class="nav-link text-light" [routerLink]="signInPath"><i class="fa fa-sign-in fa-2x" aria-hidden="true"></i>
        </a></li>
    </ul>
</div>
